import React, { useEffect } from 'react';
import { Seo, Text } from 'components';
import styled from 'styled-components';
import { mobile, tablet } from 'styles/breakpoints';
import Header from 'components/reviews/Header';
import Footer from 'components/reviews/Footer';
import LCFooter from 'components/LCFooter';

const Privacy = () => {
  useEffect(() => {
    document.body.style.overflow = 'auto';
  }, []);

  return (
    <>
      <Seo />
      <Header isMobileApp={false} />

      <Container>
        <Wrapper>
          <StyledTitle>Privacy policy</StyledTitle>
          <StyledText>
            This Privacy Policy comprises part of the Terms and Conditions
            (hereinafter referred to as the “Terms”) of UAB &quot;Medical
            Score&quot;, company code 305445861, with our registered office at
            J. Žemgulio g. 46, LT-35239 Panevėžys, the Republic of Lithuania
            (hereinafter referred to as “Mindway”, “We”, “Our”, or “Us”). All
            capitalized terms used in this Privacy Policy have the same meaning
            as in the Terms unless otherwise indicated in Clause 1 below.
          </StyledText>
          <StyledText>
            This Privacy Policy explains the way We collect and store Your
            information. By providing Personal Information or User Content to
            Us, You consent to Our collection, use, and disclosure of Your
            Personal Information in accordance with this Privacy Policy and any
            other arrangements that apply between Us. We may change our Privacy
            Policy from time to time by publishing changes to it on the
            Platform. We encourage You to check our Website periodically to
            ensure that You are aware of Our current Privacy Policy. The last
            update date of this Privacy Policy is posted at the bottom of the
            Privacy Policy.
          </StyledText>
          <StyledHeading>1. Definitions</StyledHeading>
          <StyledText>
            In this Privacy Policy, the following capitalized terms have the
            following meanings:
          </StyledText>
          <StyledText>
            Applicable Law means the data protection or privacy laws of the
            State of Delaware and the United States, applicable to Mindway and
            its clients.
          </StyledText>
          <StyledText>
            Automatic Data has the meaning given to it in Clause 3.
          </StyledText>
          <StyledText>
            Non-Personal Information means information which does not relate to
            a person and/or cannot be used to identify a person.
          </StyledText>
          <StyledText>
            Personal Information means any information which can be used to
            identify an individual, and is described in detail in Clause 2.
          </StyledText>
          <StyledText>
            User Content means any content whatsoever which are posted,
            uploaded, generated, provided, or otherwise made available by a
            client through the Platform including but not limited to any bios,
            experiences, descriptions, usage data, chats, photos, audios,
            videos, music, text, graphics, works of authorship of any kind, and
            any other information.
          </StyledText>
          <StyledHeading>
            2. What personal information do We collect?
          </StyledHeading>
          <StyledText>
            We may collect Personal Information about You when You use the
            Platform, register on the Platform, or interact with Us in any way.
            You understand that the Platform is designed to give You access to
            Counselors and Counselor Services by means of secure audio and text
            messaging. The Platform facilitates the Counselor Services and must
            therefore collect relevant personal information.
          </StyledText>
          <StyledText>
            We collect all information which You voluntarily provide to Us
            through using the Platform or interacting with Us. This includes
            information provided during registration; during support
            correspondence, questions, services, and surveys; when making
            payments; uploaded onto the Platform in any way; and when You
            register to receive any of Our newsletters.
          </StyledText>
          <StyledText>
            The Personal Information We may collect includes without limitation:
            basic information such as Your first and last name; contact
            information including email address and phone number; demographic
            information such as postal code, age, and gender; other relevant
            information such as education, profession, work experience, and
            preferences; Your contact and other information and links to Your
            social media profiles if You are a Counselor; geographic location;
            login credentials for third-party websites; insurance information;
            other information obtained by Your use of the Platform; and any
            other information that enables You to be personally identified.
          </StyledText>
          <StyledText>
            We also automatically collect certain technical data that is sent to
            Us from the device and/or browser through which You access the
            Platform or Our Website (hereinafter referred to as “Automatic
            Data”). Automatic Data includes without limitation a unique
            identifier associated with Your access device and/or browser,
            including, for example, Your internet protocol (IP) address,
            characteristics about Your access device and/or browser, statistics
            on Your activities on the Platform, details of the services We have
            provided to You or that You have questions about, including any
            additional information necessary to deliver those services and
            respond to Your questions, or information about how You came to the
            Platform. However, to the extent that IP addresses or similar
            identifiers are considered Personal Information by local law, We
            also treat these identifiers as Personal Information. We collect
            Automatic Data based on Your consent which was granted to us by You
            when You registered Your account on the Platform and accepted this
            Privacy Policy. If You do not want us to collect this information,
            delete Your account on the Platform or refrain from using the
            Platform.
          </StyledText>
          <StyledText>
            We may also collect or receive Personal Information from third-party
            sources, such as Counselors, social media, or other third-party
            integrations.
          </StyledText>
          <StyledText>
            When You interact with the Platform, We may collect Non-Personal
            Information. The limitations and requirements of this Privacy Policy
            on Our collection, use, disclosure, transfer, storage, and retention
            of Personal Information do not apply to Non-Personal Information.
            When You register for the Platform or otherwise submit Personal
            Information to Us, We may associate other Non-Personal Information
            (including Non-Personal Information We collect from third parties)
            with Your Personal Information. At such instances, We will treat any
            such combined data as Your Personal Information until it can no
            longer be associated with You or used to identify You.
          </StyledText>
          <StyledText>
            Children under the age of 18 are not permitted to create their own
            Mindway accounts unless their parent or guardian has provided
            verifiable consent, in which case the parent or guardian must review
            the Terms and this Privacy Policy before they can begin the Mindway
            account creation process for the child. If We learn that We have
            collected or received Personal Information from a child under the
            age of 18 without verification of parental consent, We will delete
            that information. If You believe We might have any information from
            or about a child under the age of 18, please contact us immediately
            by the manner described in Clause 12.
          </StyledText>
          <StyledText>
            We may ask for government-issued identification when You create an
            account on the Platform, for the purpose of verifying Your identity
            or as required by applicable law.
          </StyledText>
          <StyledHeading>
            3. Why do We collect, use, and disclose Personal Information?
          </StyledHeading>
          <StyledText>
            We may collect, hold, use, and disclose Your Personal Information
            for the following purposes: to enable You to access and use Our
            Platform, including allowing You to interact with Counselors for the
            purposes of the Counselor Services; to operate, protect, improve,
            and optimize Our Platform, business and Our users&apos; experience,
            such as to perform analytics, conduct research and for advertising
            and marketing; to send You service, support and administrative
            messages, reminders, technical notices, updates, security alerts,
            and information requested by You; to carry out Our obligations and
            enforce Our rights arising from the Agreement entered into between
            You and Us, including for billing and collection; to send You
            marketing and promotional messages and other information that may be
            of interest to You, including information sent by or on behalf of
            Our business partners that We deem relevant to You; and to comply
            with Our legal obligations, resolve any disputes that We may have
            with any of our users, enforce our agreements with third parties,
            and for anti-fraud purposes.
          </StyledText>
          <StyledText>
            From time to time, We may use Your Personal Information to send You
            important notices, including but not limited to notices about
            purchases You have made and changes made to the Terms. Due to the
            importance of this type of information, You may not opt out of
            receiving these notices.
          </StyledText>
          <StyledText>
            We may also disclose Your Personal Information to a trusted third
            party who also holds other information about You. This third party
            may combine that information in order to enable it and Us to develop
            anonymized consumer insights so that We can better understand Your
            preferences and requirements, personalize Your experience, and
            enhance the services that You receive from Us.
          </StyledText>
          <StyledText>
            The information We collect is not distributed, sold, or leased to
            third parties for commercial purposes, except to provide services
            You have requested on the Platform or for other purposes when We
            have Your permission to do so or when We are required to do so by
            law.
          </StyledText>
          <StyledText>
            We may disclose Your Personal Information if We believe disclosure
            of Your Personal Information is necessary or appropriate for
            protecting the rights, property, or safety of Mindway, our
            customers, or other persons. This includes exchanging information
            with other companies and organizations for the purposes of fraud
            protection and credit risk reduction. We limit Our uses of data for
            anti-fraud purposes to those which are strictly necessary and within
            Our assessed legitimate interests to protect Our customers and Our
            services.
          </StyledText>
          <StyledText>
            You are not obligated to provide the Personal Information that We
            have requested. However, if You chose not to provide it, We may not
            be able to provide You with our services or respond to any queries
            You may have.
          </StyledText>
          <StyledHeading>
            4. Do We use Your Personal Information for direct marketing?
          </StyledHeading>
          <StyledText>
            We may send You direct marketing notices and information about Our
            services, including but not limited to emails. By registering on the
            Platform and accepting this Privacy Policy, You hereby grant Your
            consent to receiving such notices from Us, unless You opt out as
            provided hereunder.
          </StyledText>
          <StyledText>
            You may opt out of receiving marketing materials from Us by the
            manner described in Clause 12 or by using the opt-out facilities
            provided in the aforementioned notices (e.g., an unsubscribe link).
          </StyledText>
          <StyledText>
            We do not control third parties&apos; collection or use of Your
            information to serve interest-based advertising. However, these
            third parties may provide You with ways to choose not to have Your
            information collected or used in this way.
          </StyledText>
          <StyledHeading>
            5. To whom do we disclose Your Personal Information?
          </StyledHeading>
          <StyledText>
            We may disclose Your Personal Information if We believe disclosure
            of Your Personal Information is necessary or appropriate for
            protecting the rights, property, or safety of Mindway, our
            customers, or other persons. This includes exchanging information
            with other companies and organizations for the purposes of fraud
            protection and credit risk reduction. We limit Our uses of data for
            anti-fraud purposes to those which are strictly necessary and within
            Our assessed legitimate interests to protect Our customers and Our
            services.
          </StyledText>
          <StyledText>
            In certain circumstances, We may have to disclose certain Personal
            Information to third parties in order to administer or provide the
            services that You are requesting. Personal information will never be
            shared with third parties for their marketing purposes.
          </StyledText>
          <StyledText>
            Do note that We do not disclose any Personal Information concerning
            the Counseling Services or related in any way to Your health,
            identity, or the fact that You are personally utilizing the Platform
            or the Counseling Services, to any third parties, unless You
            explicitly authorize us to do so. In addition, all Counselors who
            receive any information about You on the Platform are required to
            protect Your information and maintain its confidentiality.
          </StyledText>
          <StyledHeading>6. Using our website and cookies</StyledHeading>
          <StyledText>
            We may collect Personal Information about You when You use and
            access our website. While We do not use browsing information to
            identify You personally, We may record certain information about
            Your use of our website, such as which pages You visit, the time and
            date of Your visit, and the IP address assigned to Your device.
          </StyledText>
          <StyledText>
            We may also use &apos;cookies&apos; or other similar tracking
            technologies on Our website that help us track Your website usage
            and remember Your preferences. Cookies are small files that store
            information on Your device. They enable the entity that puts the
            cookie on Your device to recognize You across different websites,
            services, devices, and/or browsing sessions. You can disable cookies
            through Your internet browser. However, if you do so, our website
            may not work as intended for You.
          </StyledText>
          <StyledText>
            We may also use cookies to enable Us to collect data that may
            include Personal Information. We will handle any Personal
            Information collected by cookies in the same way that We handle all
            other Personal Information as described in this Privacy Policy.
          </StyledText>
          <StyledHeading>7. Data security</StyledHeading>
          <StyledText>
            We may hold Your Personal Information in either electronic or hard
            copy form. We take commercially reasonable steps to protect Your
            Personal Information from misuse, interference, and loss, as well as
            unauthorized access, modification, or disclosure, and We use a
            number of physical, administrative, personnel, and technical
            measures to protect Your Personal Information. However, We cannot
            guarantee the absolute safety and security of Your Personal
            Information.
          </StyledText>
          <StyledText>
            The safety and security of Your Personal Information also depends on
            You. Where We have given You or where You have chosen Account
            Access, You are responsible for keeping Your Account Access
            confidential and not share it with anyone. We are not responsible
            for circumvention of any privacy settings or security measures We
            provide.
          </StyledText>
          <StyledText>
            Mindway takes the safety and security of Your Personal Information
            very seriously. The Platform protects Your personal information by
            using encryption and other data security measures that are compliant
            with the U.S. Health Insurance Portability and Accountability Act
            (HIPAA). When Your Personal Information is stored by Mindway, We use
            computer systems with limited access housed in facilities using
            physical security measures. The data We collect is stored in
            encrypted form, even when We utilize third-party storage. However,
            no method of transmission over the internet or method of electronic
            storage is 100% safe and secure. While We strive to use commercially
            acceptable means of protecting Your Personal Information, We cannot
            guarantee its absolute safety and security.
          </StyledText>
          <StyledText>
            You are responsible for the Personal Information You choose to share
            or submit to others online or offline. Mindway will not take
            responsibility for Your decision to make any Personal Information
            publicly available.
          </StyledText>
          <StyledHeading>8. Retention</StyledHeading>
          <StyledText>
            We may retain Your Personal Information as long as You are
            registered on the Platform. You may delete Your account by
            contacting us in the manner described in Clause 12 or by choosing to
            do so in the settings of the Platform. However, We may retain
            Personal Information for an additional period of time as is
            permitted or required under applicable laws. Even if We delete Your
            Personal Information, it may persist on backup or archival media for
            an additional period of time for legal, tax, or regulatory reasons,
            or for legitimate and lawful business purposes.
          </StyledText>
          <StyledHeading>9. Exclusions</StyledHeading>
          <StyledText>
            Our Platform may contain links to websites operated by third
            parties. Those links are provided for convenience and may not remain
            current or be maintained. We are not responsible for the privacy
            practices of, or any content on, those linked websites, and have no
            control over or rights in those linked websites. The privacy
            policies and terms of use that apply to those other websites may
            differ substantially from Our Privacy Policy and terms, so We
            encourage individuals to read them before using those websites.
          </StyledText>
          <StyledText>
            This Privacy Policy does not apply to any Personal Information that
            You provide to anyone other than Us including but not limited to
            Counselors, through the Platform or any other means, or information
            posted by You to any clearly public areas of the Platform or Your
            social media profiles.
          </StyledText>
          <StyledText>
            You may post content on the Platform, including comments, feedback,
            pictures, and any other information You would like to make publicly
            available on the Platform by Your own initiative. Although You may
            set certain privacy settings for such information by logging into
            Your account on the Platform, be aware that no security measures are
            perfect or impenetrable. Additionally, We cannot control the actions
            of third parties with whom You may choose to share Your User
            Content. Therefore, We cannot and do not guarantee that Your User
            Content will not be viewed or used by unauthorized persons that may
            violate this Privacy Policy, the law, or Your personal privacy.
          </StyledText>
          <StyledHeading>
            10. Accessing or correcting Your Personal Information
          </StyledHeading>
          <StyledText>
            You can access the Personal Information We have about You by the
            manner described in Clause 12. We may not be able to provide You
            with access to all of Your Personal Information, in which case We
            will inform You of the reason why that is so. We may also need to
            verify Your identity when You request Your Personal Information.
          </StyledText>
          <StyledText>
            If You think that any Personal Information We have about You is
            inaccurate, inform us by the manner described in Clause 12 and We
            will take reasonable steps to ensure that it is corrected.
          </StyledText>
          <StyledText>
            We will consider and respond to all requests in accordance with all
            applicable laws.
          </StyledText>
          <StyledHeading>11. Making a complaint</StyledHeading>
          <StyledText>
            If You have a reason to believe We have breached any applicable law,
            or You wish to make a complaint about the manner by which We have
            handled Your Personal Information, You may contact us by the manner
            described in Clause 12. We will acknowledge Your complaint and
            respond to You within a reasonable period of time. If You think that
            We have failed to resolve the complaint satisfactorily, We will
            provide You with information about the further steps You can take.
          </StyledText>
          <StyledHeading>12. Contact us</StyledHeading>
          <StyledText>
            For further information about Our Privacy Policy or practices, or to
            access or correct Your Personal Information, or to make a complaint,
            please contact us by sending an email to help@mindway.app
          </StyledText>
          <StyledHeading>
            13. Your rights under the California Consumer Privacy Act
          </StyledHeading>
          <StyledText>
            To the extent that the California Consumer Privacy Act (“CCPA”) is
            applicable to either Mindway or You: both parties agree to comply
            with all of its obligations under the CCPA. In relation to any
            communication of &apos;Personal Data&apos; as defined by the CCPA,
            the parties agree that no monetary or other valuable consideration
            is being provided for such Personal Data and therefore neither party
            is &apos;selling&apos;, as defined by the CCPA, Personal Data to the
            other party, unless specifically agreed for and consented to by the
            parties.
          </StyledText>
          <StyledText>
            The principal rights You have under the CCPA include but are not
            limited to: the right to know the Personal Data We collect from You;
            the right to request the deletion of Your Personal Data; the right
            not to be discriminated against for having exercised Your rights
            under the CCPA; the right to opt out from the collection and/or sale
            of Your Personal Data; and the right to access Your Personal Data.
          </StyledText>
          <StyledText>
            Both parties agree to notify the other immediately if it receives
            any complaint, notice, or communication that directly or indirectly
            relates to either party&apos;s compliance with the CCPA.
            Specifically, We shall notify You within 10 working days if We
            receive a verifiable consumer request under the CCPA.
          </StyledText>
          <StyledText>Last Updated: January 1st, 2024</StyledText>
        </Wrapper>
      </Container>
      <LCFooter disclaimerText={['© ${year} Mindway. All rights reserved']} />
    </>
  );
};

export default Privacy;

const MainContainer = styled.div`
  width: 100vw;
  background-size: cover;
  padding-top: 69px;
`;

const Wrapper = styled.div`
  margin: auto;
  width: 100%;
  max-width: 84rem;
`;

const StyledTitle = styled(Text)`
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 47px;
  font-family: Rubik;
  @media ${mobile} {
    font-size: 2rem;
  }
`;

const Container = styled.div`
  padding: 48px 3rem 40px;
  @media ${tablet} {
    padding: 48px 16px 40px;
  }
`;

const StyledText = styled(Text)`
  font-family: Rubik;
  margin-bottom: 1.375rem;
  color: #28293d;
  font-weight: 300;
  letter-spacing: -0.024em;
`;

const StyledHeading = styled(Text)`
  font-weight: 600;
  font-family: Rubik;
  margin-bottom: 1.5rem;
  font-size: 1.25rem;
`;
