import React, { useEffect } from 'react';
import Header from '../../components/reviews/Header';
import MainReviewSection from 'components/reviews/MainReviewSection';
import VideoReviewsSection from 'components/reviews/VideoReviewsSection';
import HappyAppUsersSection from 'components/reviews/HappyAppUsersSection';
import SuccessStoriesSection from 'components/reviews/SuccessStoriesSection';
import styled from 'styled-components';
import Footer from 'components/reviews/Footer';
import { Seo } from 'components';
import LCFooter from 'components/LCFooter';

const Reviews = () => {
  useEffect(() => {
    document.body.style.overflow = 'auto';
  }, []);

  return (
    <>
      <Seo
        title="Mindway App Review"
        name="Mindway App Review"
        description="Read how mindway app users managed to stop overthinking using our personalized plan. Read the mindway app reviews now!"
      />
      <Header isMobileApp={false} />
      <MainReviewSection />
      <VideoReviewsSection />
      <HappyAppUsersSection />
      <SuccessStoriesSection />
      <LCFooter disclaimerText={['© ${year} Mindway. All rights reserved']} />
    </>
  );
};

export default Reviews;

export const StyledSection = styled.section<{
  backgroundColor: string;
  sectionPadding: string;
  marginTop: string;
}>`
  height: fit-content;
  width: 100%;
  background: ${({ backgroundColor = '#f6f4ef' }) => backgroundColor};
  padding: ${({ sectionPadding = '56px 16px' }) => sectionPadding};
  margin-top: ${({ marginTop = '69px' }) => marginTop};
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (max-width: 48em) {
    padding: 38px 16px;
  }

  p,
  strong,
  a,
  h1,
  h2,
  span {
    font-family: Rubik, sans-serif;
  }

  p,
  strong,
  h1 {
    color: #000 !important;
  }
`;

export const SectionHeading = styled.h1<{ marginBottom: string }>`
  color: #000;
  font-weight: 700;
  font-size: 28px;
  line-height: 140%;
  text-align: center;
  margin-top: 0;
  margin-bottom: ${({ marginBottom = '8px' }) => marginBottom};
`;

export const SectionSubheading = styled.h2<{ marginBottom: string }>`
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  color: #595b60;
  margin-top: 0;
  margin-bottom: ${({ marginBottom = '10px' }) => marginBottom};
`;
